<template>
  <div class="medium-page">
    <!-- If we don't have the data just yet -->
    <template v-if="isLoading || !overview">
      <div
        class="mx-auto"
        style="max-width: 360px"
      >
        <lottie-animation
          loop
          key="antenna"
          file="loading-files-StOdYbIRa1.json"
        />
      </div>
    </template>

    <!-- Otherwise -->
    <template v-else>
      <!-- If we don't have any influencer in this group -->
      <template v-if="overview.influencers === 0">
        <!-- The header buttons -->
        <div class="d-flex justify-space-between">
          <!-- Show the go back button -->
          <v-btn text color="primary" @click="$router.push({ name: 'InfluencerGroupsIndex' })">
            <v-icon left> arrow_back </v-icon>

            Groups
          </v-btn>
        </div>

        <!-- Show an animation first -->
        <div class="mx-auto" style="max-width: 360px">
          <lottie-animation loop key="shaking-box" file="106964-shake-a-empty-box.json" />
        </div>

        <!-- Show the text -->
        <div class="text-center text-h6 font-weight-bold">You don't have any influencer in this group</div>

        <div class="text-center mt-2">Start by adding influencers below</div>

        <!-- Show the button -->
        <div class="text-center">
          <v-btn depressed class="mt-6" color="primary" @click="shouldShowAddInfluencerDialog = true">
            <v-icon left> add </v-icon>

            Add Influencers
          </v-btn>
        </div>
      </template>

      <div
        v-else
        class="influencer-groups-child-view"
      >
        <router-view
          :key="reloadKey"
          :overview="overview"
          @refreshData="fetchDetails"
          @triggerAddInfluencer="handleAddInfluencer"
        />
      </div>

      <!-- Show the add influencers dialog -->
      <add-influencers
        v-model="shouldShowAddInfluencerDialog"
        :selected-platform="selectedPlatform"
        :overview="overview"
        :include-posts-input="false"
        module="influencerGroups"
        @refresh="fetchDetails"
      />
    </template>
  </div>
</template>

<script>
// Import children components
const LottieAnimation = () => import(/* webpackChunkName: "lottie-animation" */ "@/components/common/LottieAnimation.vue")
const AddInfluencers = () => import(/* webpackChunkName: "crm-add-influencers" */ "@/components/crm/AddInfluencers.vue")

// Import helper functions
import messageEvents from "@/helpers/messageEvents"

// Define the key for handling subscriptions
const subscriptionId = Symbol("InfluencerGroups/View")

// Export the SFC
export default {
  // Name of the component
  name: "InfluencerGroupsView",

  // Register children components
  components: {
    LottieAnimation,
    AddInfluencers,
  },

  // Define local data variables
  data: () => ({
    // Whether or not to show the add influencer dialog
    shouldShowAddInfluencerDialog: false,

    // If the data is being loaded
    isLoading: false,

    // The reload key to make sure the UI is always up-to-date
    reloadKey: null,
  }),

  // Define readonly data variables
  computed: {
    /**
     * Whether or not the user is authenticated
     *
     * @returns {Boolean}
     */
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]
    },

    /**
     * Get the model data from Vuex store
     *
     * @returns {Object}
     */
    overview() {
      // Get the data from store
      return this.$store.getters["influencerGroups/detailByUuid"](this.$route.params.uuid)
    },

    /**
     * Get the currently selected platform
     *
     * @returns {String|Null}
     */
    selectedPlatform() {
      return this.overview ? this.$store.getters["influencerGroups/selectedPlatformById"](this.overview.model.id) : null
    },
  },

  // Define local method functions
  methods: {
    /**
     * When called, fetch and update the data in Vuex store
     *
     * @returns {void}
     */
    async fetchDetails() {
      // Show a loader
      const loaderId = Symbol()
      this.$store.dispatch("loaders/add", loaderId)
      this.isLoading = true

      // Also, reload the children view
      this.reloadKey = Symbol()

      // Wait for the load to be completed
      await this.$store.dispatch("influencerGroups/fetchDetails", this.$route.params.uuid)

      // Hide the loaders
      this.$store.dispatch("loaders/remove", loaderId)
      this.isLoading = false
    },

    /**
     * Show the dialog box for adding influencers
     *
     * @returns {void}
     */
    handleAddInfluencer() {
      // If the selected platform is not set
      if (!this.selectedPlatform) {
        // Select the first platform available in the list
        this.$store.dispatch("influencerGroups/updateSelectedPlatform", {
          id: this.overview.model.id,
          value: this.overview?.model?.platforms?.[0] || null
        })
      }

      // Show the dialog
      this.shouldShowAddInfluencerDialog = true
    },

    /**
     * When triggered, refresh the data to fetch updated list of influencers
     *
     * @returns {void}
     */
    handleNotificationRefreshAction() {
      // Dispatch a request to create a network request
      this.fetchDetails()
    },

    /**
     * Handle the message event for influencers import
     *
     * @param {Object} event
     * @returns {void}
     */
    handleMessageEvent(event) {
      // If the import just started
      if (event.key === "influencers-import-started") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "Your influencers are being added to the group!",
          type: "info",
          icon: "lightbulb",
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }

      // If a chunk of import has been completed
      if (event.key === "influencers-import-completed") {
        // Fire a notification
        this.$store.dispatch("notifications/add", {
          text: event.localData.text || "A bunch of your influencers have been added, check progress!",
          type: "success",
          icon: "check_circle",
          button: {
            text: "Refresh",
            action: this.handleNotificationRefreshAction
          },
          event: {
            module: event.module,
            type: event.type,
            key: event.key
          }
        })
      }
    },
  },

  /**
   * As soon as the component data has been created
   *
   * @returns {void}
   */
  created() {
    // Dispatch a request to create a network request
    this.fetchDetails()

    // Register a subscriber for messageEvents
    messageEvents.register({
      id: subscriptionId,
      module: "influencer-groups",
      type: "all",
      key: "all",
      validator: (event) => event.module === "influencer-groups",
      callback: this.handleMessageEvent
    })
  },

  /**
   * Right before the component is about to be un-rendered
   *
   * @returns {void}
   */
  beforeDestroy() {
    // De-register the subscriber for messageEvents
    messageEvents.deregister(subscriptionId)
  }
}
</script>